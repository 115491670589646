import React from 'react';

export default function FrontLoading() {
  return (
    <div className="front-loading">
      <lottie-player 
        src="https://assets4.lottiefiles.com/datafiles/0iySG00VZaLTdlE/data.json"  background="transparent"  speed="1"  style={{width: "100%", height: "100%"}}  loop  autoplay >
      </lottie-player>
    </div>
  );
}
