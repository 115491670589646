import React from 'react';
import { Container, Card, Image, Button, Icon } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import defaultImage from './assets/default.png'
import emptyImage from './assets/empty.svg' 
import actionTypes from './store/actions/actionTypes';
import { NotificationManager } from 'react-notifications'

export default function PokemonFav() {
  const dispatch = useDispatch();
  const pokemons = useSelector(state => state.pokemons.favPokemons);
  const history = useHistory();

  // Function
  const getImageUrl = (url) => {
    
    url = url.substring(34).replace('/', '');
    const imgUrl = '00' + url;
    
    if (imgUrl.length === 5) return `https://assets.pokemon.com/assets/cms2/img/pokedex/full/${imgUrl.substring(2, 5)}.png`;
    if (imgUrl.length === 4) return `https://assets.pokemon.com/assets/cms2/img/pokedex/full/${imgUrl.substring(1, 4)}.png`;
    if (imgUrl.length === 3) return `https://assets.pokemon.com/assets/cms2/img/pokedex/full/${imgUrl.substring(0, 3)}.png`;
    else return defaultImage;
    
  }

  const goToPokemonDetail = (url) => {
    console.log(url)
    let id = getImageUrl(url).substring(56, 59)
    history.replace('/pokemons/' + id)
  }

  const removeFavourite = (event, id) => {
    event.preventDefault()
    dispatch({ type: actionTypes.REMOVE_FAVOURITE, payload: id })
    NotificationManager.error('Removed 1 pokemons from favourite', 'Removed', 1500)
  }

  if (pokemons.length === 0) {
    return (
      <Container className="pokemon-favourite">
        <h1 className="title-page">Favourite Pokemon</h1>
        <h4>Ooppss.. You haven't add any pokemon to favourite :(</h4>
        <Image src={emptyImage} />
      </Container>
    )
  }
  return (
    <Container className="pokemon-favourite">
      <h1 className="title-page">Pokemon Favourite</h1>
      <Card.Group stackable itemsPerRow={4} centered>
      {pokemons.map((pokemon) => (
          <Card className="pokemon-card" key={pokemon.url} >
            <Image onClick={() => goToPokemonDetail(pokemon.url)} className="pokemon-image" src={getImageUrl(pokemon.url)} wrapped ui={false} />
            <Card.Content>
              <Card.Header style={{fontSize: "2rem"}} textAlign={"center"}>{pokemon.name.toUpperCase()}</Card.Header>
              <Card.Meta textAlign={"right"} style={{fontSize: "1em"}}>
              </Card.Meta>
            </Card.Content>
            <Button onClick={(event) => removeFavourite(event, pokemon.id)} color="red"> <Icon name="remove circle" size="large" /> </Button>
          </Card>
        ))}
      </Card.Group>
    </Container>
  );
}
