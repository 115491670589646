import React from 'react'
import { Link } from 'react-router-dom'

export default function Landing() {
  return (
    <div id="dashboard">
      <lottie-player
        src="https://assets5.lottiefiles.com/temp/lf20_Tw0dyZ.json"  background="transparent"  speed="1"  style={{width: "50vw", height: "50vh"}} loop  autoplay >
      </lottie-player>
      <h1 id="dashboard-title"> WELCOME TO REACT <u id="landing-pokemon">POKEMONS</u> API </h1>
      <Link className="nav-btn ui button" to="/pokemons"> LET'S GOOO </Link>
    </div>
  )
}