import React, { useEffect } from 'react';
import { Container, Card, Image, Button, Icon } from 'semantic-ui-react';
import defaultImage from './assets/default.png';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from './store/actions/index';
import { NotificationManager } from 'react-notifications';

// Components
import FrontLoading from './components/FrontLoading'

export default function PokemonList() {
  const pokemons = useSelector((state) => state.pokemons.pokemons)
  const isLoading = useSelector((state) => state.pokemons.isLoading)
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allActions.fetchPokemons())
  }, [dispatch])

  const getId = (url) => {
    return getImageUrl(url).substring(56, 59)
  }
  
  const getImageUrl = (url) => {
    
    url = url.substring(34).replace('/', '');
    const imgUrl = '00' + url;
    
    if (imgUrl.length === 5) return `https://assets.pokemon.com/assets/cms2/img/pokedex/full/${imgUrl.substring(2, 5)}.png`;
    if (imgUrl.length === 4) return `https://assets.pokemon.com/assets/cms2/img/pokedex/full/${imgUrl.substring(1, 4)}.png`;
    if (imgUrl.length === 3) return `https://assets.pokemon.com/assets/cms2/img/pokedex/full/${imgUrl.substring(0, 3)}.png`;
    else return defaultImage;
    
  }

  const goToPokemonDetail = (url) => {
    let id = getId(url)
    history.push('/pokemons/' + id)
  }

  const addFavourite = (event, pokemon) => {
    event.preventDefault()
    NotificationManager.success(`Added ${pokemon.name} to favourite`, "Success", 2500)
    dispatch(allActions.addFavourite(pokemon))
  }

  return (
    <div className="pokemon-list" id="pokemon-list-page">
      { isLoading && <FrontLoading />}
      <Container>
        <h1 className="title-page" >Pokemon List</h1>
        <Card.Group  stackable itemsPerRow={4} centered>
          {pokemons.map((pokemon) => (
            <Card className="pokemon-card" key={getId(pokemon.url)} >
              <Image onClick={() => goToPokemonDetail(pokemon.url)} className="pokemon-image" src={getImageUrl(pokemon.url)} wrapped ui={false} />
              <Card.Content>
                <Card.Header style={{fontSize: "2rem"}} textAlign={"center"}>{pokemon.name.toUpperCase()}</Card.Header>
                <Card.Meta textAlign={"right"} style={{fontSize: "1em"}}>
                  <span> <i> #{getImageUrl(pokemon.url).substring(56, 59)} </i> </span>
                </Card.Meta>
              </Card.Content>
              <Button.Group>
                <Button icon color="green" onClick={() => goToPokemonDetail(pokemon.url)}><Icon name="file alternate outline" size="large" /></Button>
                <Button.Or text='' />
                <Button icon color='red' onClick={(event) => addFavourite(event, pokemon)}><Icon name="like" size="large"/></Button>
              </Button.Group>
            </Card>
          ))}
        </Card.Group>
      </Container>
      <Button className="nav-to-top" inverted color="red" href="#" circular icon="arrow up" />
    </div>
  )

}
