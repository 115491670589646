import React from 'react';
import pageNotFound from '../assets/404.svg'
import { Image } from 'semantic-ui-react'

export default function NotFound() {
  return (
    <div class="not-found">
      <h2>Ooopppss... page not found :(</h2>
      <Image src={pageNotFound} />
    </div>
  );
}
