import actionTypes from './actionTypes'
import axios from 'axios'

export const addFavourite = ({ name, url }) => {
  return {
    type: actionTypes.ADD_FAVOURITE,
    payload: { name, url }
  }
}

export const removeFavourite = ({ id }) => {
  return {
    type: actionTypes.REMOVE_FAVOURTE,
    payload: { id }
  }
}

export const fetchPokemons = () => dispatch => {
  dispatch({ type: actionTypes.ISLOADING_START })
  axios
    .get("https://pokeapi.co/api/v2/pokemon/?limit=40&offset=0")
    .then(({ data }) => {
      dispatch({
        type: actionTypes.FETCH_POKEMONS, 
        payload: data
      })
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {
      dispatch({ type: actionTypes.ISLOADING_STOP })
    })
}
