import React from 'react';
import './style.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

// Components
import PokemonList from './PokemonList'
import PokemonDetail from './PokemonDetail'
import PokemonFav from './PokemonFav'
import Landing from './views/Landing'
import Navbar from './components/Navbar'
import NotFound from './views/NotFound.js'
import { NotificationContainer } from 'react-notifications'

export default function App() {

  return ( 
    <Router>
      <div className="app">
        <Navbar />
        <Switch>

          <Route exact path="/pokemons">
            <PokemonList/>
          </Route>

          <Route exact path="/pokemons/:id">
            <PokemonDetail />
          </Route>

          <Route exact path="/favourites">
            <PokemonFav />
          </Route>

          <Route exact path="/" >
            <Landing />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>

        </Switch>
      </div>
      <NotificationContainer />
    </Router>
    )
}