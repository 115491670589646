import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Container, Grid, Image, Header, List } from 'semantic-ui-react'
import axios from 'axios';
import ImageLoading from './components/ImageLoading.js'


export default function PokemonDetail() {

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.pokemons.isLoading);
  const { id } = useParams();
  const [pokemon, setPokemon] = useState({ stats: [] })
  let pokemonId;

  if (id[0] === "0") { pokemonId = id.substring(1, 3) }
  if (id[0] === "0" && id[1] === "0") { pokemonId = id.substring(2) }
  
  useEffect(() => {
    dispatch({ type: "ISLOADING_START"})
    axios.get('https://pokeapi.co/api/v2/pokemon/'+ pokemonId)
      .then(response => {
        const data = response.data
        data.imageUrl = `https://assets.pokemon.com/assets/cms2/img/pokedex/full/${id}.png`
        setPokemon(data)
      })
      .catch(err => [
        console.log(err)
      ])
      .finally(() => {
        dispatch({ type: "ISLOADING_STOP"})
      })
  }, [pokemonId, dispatch, id])

  return (
    <Container className="pokemon-detail" >
      <Grid centered columns={1}>
        { isLoading && <Grid.Row> <ImageLoading /> </Grid.Row> }
        <Grid.Row>
          <Image src={pokemon.imageUrl} onLoad={() => dispatch({ type: "ISLOADING_STOP"})} alt={pokemon.name} />
        </Grid.Row>
        <Grid.Row className="pokemon-name" >
          <Header as="h1">{pokemon.name && pokemon.name.toUpperCase()}</Header>
        </Grid.Row>
        <Grid.Row className="pokemon-id">
          #{id}
        </Grid.Row>
        <Grid.Row className="pokemon-stats">
          <Header as="h2">POKEMON STATS</Header>
        </Grid.Row>
        <Grid.Row className="pokemon-stats">
          <List>
            {pokemon.stats.map((stat) => <List.Item className="pokemon-stat" key={stat.stat.name}> {stat.stat.name}: {stat.base_stat}</List.Item> )}
          </List>
        </Grid.Row>
      </Grid>
    </Container>
  )
}