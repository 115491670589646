import React, { useState } from 'react'
import { Menu } from 'semantic-ui-react'
import { Link, useRouteMatch } from 'react-router-dom'

export default function Navbar() {
  const isLanding = useRouteMatch({ path: '/' }).isExact
  const [state, setState] = useState({})
  const handleItemClick = (e, { name }) => setState({ activeItem: name })
  const { activeItem } = state
  
  if (isLanding) {
    return null
  }

  return (
    <Menu className="navbar-menu">
      
      <Link to="/"> 
        <Menu.Item
        as='div'
        name='home'
        active={activeItem === 'home'}
        onClick={handleItemClick}
        >
          Home
        </Menu.Item>
      </Link>

      <Link to="/pokemons"> 
        <Menu.Item
        as='div'
        name='pokemons'
        active={activeItem === 'pokemons'}
        onClick={handleItemClick}
        >
          Pokemons
        </Menu.Item>
      </Link>

      <Link to="/favourites"> 
        <Menu.Item
        as='div'
        name='favourites'
        active={activeItem === 'favourites'}
        onClick={handleItemClick}
        >
          Favourites
        </Menu.Item>
      </Link>
      
    </Menu>
  )
}