import actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  pokemons: [],
  favPokemons: []
}

const pokemonReducers = (state = initialState, action ) => {
  
  switch(action.type) {

    case actionTypes.ADD_FAVOURITE:
      return {
        ...state, 
        favPokemons: [...state.favPokemons, { 
          id: state.favPokemons.length + 1,
          name: action.payload.name, 
          url: action.payload.url
        }]
      }
    
    case actionTypes.REMOVE_FAVOURITE:
      return {
        ...state,
        favPokemons: [...state.favPokemons].filter(favPokemon => favPokemon.id !== action.payload )
      }

    case actionTypes.FETCH_POKEMONS: 
      return {
        ...state,
        pokemons: action.payload.results
      }

    case actionTypes.ISLOADING_START:
      return {
        ...state,
        isLoading: true
      }

    case actionTypes.ISLOADING_STOP:
      return {
        ...state,
        isLoading: false
      }

    default:
      return state
  }
}

export default pokemonReducers